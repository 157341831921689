// Generated by Framer (89417f6)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, RichText, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["InzqznKU3", "XL9UUMQ5Q", "AW7Kj77k8"];

const variantClassNames = {AW7Kj77k8: "framer-v-hhm89n", InzqznKU3: "framer-v-1fbmpzc", XL9UUMQ5Q: "framer-v-g6uhst"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}};

const transformTemplate = (_, t) => `translateY(-50%) ${t}`

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {"Variant 1": "InzqznKU3", "Variant 2": "XL9UUMQ5Q", "Variant 3": "AW7Kj77k8"}

const getProps = ({height, id, title, width, ...props}) => { return {...props, RpVFkeoaO: title ?? props.RpVFkeoaO ?? "Design Studio Next Gen", variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "InzqznKU3"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;title?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale } = useLocaleInfo()

const {style, className, layoutId, variant, RpVFkeoaO, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "InzqznKU3", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onMouseEnter1dxau1p = activeVariantCallback(async (...args) => {
setVariant("XL9UUMQ5Q")
})

const onMouseLeavefzjnt = activeVariantCallback(async (...args) => {
setVariant("AW7Kj77k8")
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-eydUE", classNames)} style={{display: "contents"}}>
<Transition value={transition}><motion.div {...restProps} className={cx("framer-1fbmpzc", className)} data-framer-name={"Variant 1"} data-highlight layoutDependency={layoutDependency} layoutId={"InzqznKU3"} onMouseEnter={onMouseEnter1dxau1p} ref={ref} style={{...style}} {...addPropertyOverrides({AW7Kj77k8: {"data-framer-name": "Variant 3"}, XL9UUMQ5Q: {"data-framer-name": "Variant 2", onMouseLeave: onMouseLeavefzjnt}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7TGVhZ3VlIFNwYXJ0YW4tNzAw", "--framer-font-family": "\"League Spartan\", \"League Spartan Placeholder\", sans-serif", "--framer-font-weight": "700", "--framer-line-height": "100px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv, rgb(255, 255, 255))"}}><motion.span style={{"--framer-font-size": "89.3333px"}}>Design Studio Next Gen</motion.span></motion.p></React.Fragment>} className={"framer-jr4rsy"} data-framer-name={"Nadpis"} fonts={["GF;League Spartan-700"]} layoutDependency={layoutDependency} layoutId={"UeNkxdzgC"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={RpVFkeoaO} transformTemplate={transformTemplate} verticalAlignment={"top"} withExternalLayout/></motion.div></Transition>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-eydUE [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-eydUE .framer-xcljbr { display: block; }", ".framer-eydUE .framer-1fbmpzc { height: 235px; overflow: hidden; position: relative; width: 639px; }", ".framer-eydUE .framer-jr4rsy { -webkit-user-select: none; flex: none; height: auto; left: 0px; pointer-events: auto; position: absolute; right: 0px; top: 50%; user-select: none; white-space: pre-wrap; word-break: break-word; word-wrap: break-word; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 235
 * @framerIntrinsicWidth 639
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XL9UUMQ5Q":{"layout":["fixed","fixed"]},"AW7Kj77k8":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"RpVFkeoaO":"title"}
 * @framerImmutableVariables false
 */
const FramersfcpmcbZY: React.ComponentType<Props> = withCSS(Component, css, "framer-eydUE") as typeof Component;
export default FramersfcpmcbZY;

FramersfcpmcbZY.displayName = "Nadpis";

FramersfcpmcbZY.defaultProps = {height: 235, width: 639};

addPropertyControls(FramersfcpmcbZY, {variant: {options: ["InzqznKU3", "XL9UUMQ5Q", "AW7Kj77k8"], optionTitles: ["Variant 1", "Variant 2", "Variant 3"], title: "Variant", type: ControlType.Enum}, RpVFkeoaO: {defaultValue: "Design Studio Next Gen", displayTextArea: false, title: "Title", type: ControlType.String}} as any)

addFonts(FramersfcpmcbZY, [{family: "League Spartan", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/sfcpmcbZY:default", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/leaguespartan/v11/kJEnBuEW6A0lliaV_m88ja5Twtx8BWhtkDVmjZvMGYPpBMdcFguczA.ttf", weight: "700"}])